import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerHomeProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const ContentWrapper = styled.div`
  padding: 100px 0 85px 0;

  & h1,
  h2 {
    font-size: 35px;
    line-height: 40px;
  }

  @media (max-width: 991px) {
    padding: 90px 0 0 0;
  }

  @media (max-width: 767px) {
    & h1 {
      font-size: 26px;
    }

    & h2 {
      font-size: 30px;
    }

    & p {
      margin-bottom: 0;
    }
  }
`

const Dot = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 6px;
  width: 6px;
  border-radius: 100%;
  margin: 0 20px;

  @media (max-width: 767px) {
    margin: 0 10px;
  }
`

const Usp = styled.div`
  font-size: 35px;
  line-height: 40px;
  color: ${({ theme }) => theme.color.primary};
  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  @media (max-width: 767.98px) {
    max-width: 500px;
  }

  @media (max-width: 575.98px) {
    max-width: 350px;
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    max-width: 700px;
  }

  @media (max-width: 991px) {
    margin-top: -100px;
  }

  @media (min-width: 992px) {
    position: absolute !important;
    bottom: 0;
    right: 0;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  return (
    <StyledBannerHome>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 position-relative" style={{ zIndex: 1 }}>
            <ContentWrapper>
              <div className="d-flex align-items-center pb-lg-5 pb-3">
                {fields.usps?.map((usp, index) => (
                  <React.Fragment key={index}>
                    <Usp key={index}>{usp?.usp}</Usp>
                    {(fields.usps?.length || 3) - 1 !== index && <Dot />}
                  </React.Fragment>
                ))}
              </div>
              <ParseContent content={fields.description || ''} />
            </ContentWrapper>
          </div>
          <div className="col-lg-6 position-relative">
            <StyledPlaatjie image={fields.image} alt="" />
          </div>
        </div>
      </div>
    </StyledBannerHome>
  )
}

export default BannerHome
